import Modal from 'bootstrap/js/dist/modal'

const globalFunctions = {
    data() {
        return{ }
    },
    methods: {
        addScript(url){
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.src = url;
            $("body").append(s);
        },
        $can(permissionName) {
            if(permissionName?.perm && this.$page.props.permissions.length > 0){
                if(permissionName.perm == 'default'){
                    return true
                }
                return this.$page.props.permissions.indexOf(permissionName.perm) !== -1;
            }else{
                if(permissionName != ''){
                    return this.$page.props.permissions.indexOf(permissionName) !== -1;
                }
            }
            return false
            // else
                // return true
            //     window.location.reload()
        },
        catchError( error ) {
            this.stopLoading()
            let localErrors = []
            if(error.response?.code == 401){
                window.location.href = '/login'
            }else if(typeof error.response?.data === 'object'){
                localErrors = _.flatten(_.toArray(error.response.data.errors));
            }else{
                localErrors = ['Algo salió mal :('];
            }

            for(let i = 0; i < localErrors.length; i++){
                this.showToast('error', localErrors[i])
            }
        },
        checkSidebar(){
            if(!this.sidebar){
                $('.fixed-sidebar .app-main .app-main__outer').css({'padding-left': 0});
            }
        },
        closeModal() {
            if(window.myModal){
                window.myModal.hide()
                window.myModal = null
            }
        },
        costCurrency(cost, currency) {
            if(currency == undefined || currency == ''){
                currency = 'MXN'
            }
            const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: currency})
            return formatter.format(cost)
        },
        async deleteData(url, loadData, emitEvent, titleLoading, titleMessage, callback) {
            Swal.fire({
                title: 'Aviso',
                text: "¿Está seguro de realizar esta acción?.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, realizarla!',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if(result.isConfirmed){
                    this.openLoading(titleLoading)
                    await axios.delete(url)
                        .then((response) => {
                            if(response.data.status == true){
                                this.closeModal()
                                this.showMessage(titleMessage, response.data.message, 'success', '', 1, emitEvent, response)
                                callback()
                            }else{
                                Swal.close()
                                this.showMessage('Error', response.data.message, 'error', '')
                            }
                        })
                        .catch( error => {
                            this.catchError(error)
                        })
                }
            })
        },
        filtersidebar_toggle(value){
            this.filtersidebar_toggle_var = value
        },
        async getFilterSelect(url, query, field){
            this.query_params = {
                [field]: query
            }

            return await axios.get(url, { params: this.query_params })
                .then( response => {
                    return response.data.data
                })
        },
        async getFilterSelectObject(url, query, field){
            this.query_params = {
                [field]: query
            }
            return await axios.get(url, { params: this.query_params })
                .then( response => {
                    let options = []
                    response.data.data.forEach(e => {
                        options.push({
                            id: e.id,
                            name: e.name,
                            value: e
                        })
                    })
                    return options
                })
        },
        openLoading(title) {
            Swal.fire({
                title,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        },
        openModal(modal) {
            window.myModal = new Modal(document.getElementById(modal))
			window.myModal.show()
        },
        openTransfer(project){
            this.model = project
            this.openModal('create-transfer')
        },
        randomElement(array) {
            return array[Math.floor(Math.random()*array.length)];
        },
        async saveData(url, model, urlReturn, emitEvent, closemodal, clearmodel, tipoMensaje, modelOriginal, titleLoading, titleMessage, emitMessage) {
            this.openLoading('Espere por favor...')
            await axios.post(url, model)
                .then( response => {
                    if(response.data.status == true){
                        this.showMessage('Exito', response.data.message, 'success', urlReturn, emitMessage, emitEvent, response)
                        this.$emit('success')
                        if(closemodal)
                            this.closeModal()
                    }
                })
                .catch( error => {
                    this.catchError(error)
                })
        },
        showMessage(title, text, icon, redirectTo, emitMessage, emitEvent, response) {
            Swal.fire({
                title,
                text,
                icon,
                willClose: () => {
                    if(redirectTo != '')
                        window.location.href = redirectTo
                    if(emitMessage == 1)
                        this.closeModal()
                }
            })
        },
        showToast(icon, message){
            var Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: icon,
                title: message
            })
        },
        slugify(string) {
            return string
              .toString()
              .trim()
              .toLowerCase()
              .replace(/\s+/g, "-")
              .replace(/[^\w\-]+/g, "")
              .replace(/\-\-+/g, "-")
              .replace(/^-+/, "")
              .replace(/-+$/, "");
        },
        stopLoading() {
            Swal.close()
        },
        toggleHide(){
            $(".toggle-password").click(function() {
                $(this).toggleClass("fa-eye fa-eye-slash");
                var input = $($(this).attr("toggle"));

                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            });
        },
        async updateData(url, model, urlReturn, emitEvent, closemodal, clearmodel, tipoMensaje, modelOriginal, titleLoading, titleMessage, emitMessage) {
            this.openLoading(titleLoading)

            await axios.put(url, model)
                .then( response => {
                    this.stopLoading()
                    if (response.data.status == true) {
                        this.showMessage(titleMessage, response.data.message, 'success', urlReturn, emitMessage, emitEvent, response)
                        this.$emit('success')
                        if(closemodal)
                            this.closeModal(closemodal)
                    }
                    if (response.data.status == false) {
                        this.showMessage('Error', response.data.message, 'error', '')
                    }
                })
                .catch( error => {
                    this.catchError(error)
                })
        },
        async viewLesson(lesson_id){
            await axios.post('/api/view-lesson', {lesson_id: lesson_id})
        },
    }
}

export default globalFunctions