import Jobs from '@/Compost/data/jobs'


const state = {
	jobs : Jobs.jobs
}

// getters
const getters = {

}

// mutations
const mutations = {
};

// actions
const actions = {
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}