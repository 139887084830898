import './bootstrap';
import '../css/app.css';
import '../scss/style.scss';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

import { createI18n, useI18n } from "vue-i18n";

import BootstrapVue from 'bootstrap-vue-3'
import VueFeather from 'vue-feather';
import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts from 'vue3-apexcharts'

import globalFunctions from './Mixins/globalFunctions'

import SWAL from 'sweetalert2'
window.Swal = SWAL

import Toasted from '@meforma/vue-toaster'
import VueForm from 'vue-form'
import { store } from './store'
import {TippyPlugin} from 'tippy.vue';

import vueDebounce from 'vue-debounce'
import * as Sentry from "@sentry/vue";

console.log('environment', import.meta.env.VITE_SENTRY_ENVIRONMENT)
// import VueChartist from 'vue-chartist'
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        if(import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'local'){
          Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
          });

        }

        app.use(plugin)
          .use(ZiggyVue, Ziggy)
          .use(TippyPlugin)
          .use(vueDebounce)
          .mixin(globalFunctions)
          .use(VueForm)
          .use(store)
          .use(VueSweetalert2)
          .use(BootstrapVue)
          .use(Toasted,{ iconPack: 'fontawesome' })
          .component(VueFeather.name, VueFeather)
          .component('apexchart', VueApexCharts)
          // .use(VueChartist)
          .mount(el);

        return app;
    },
});

InertiaProgress.init({ color: '#4B5563' });
